<template>
<div id="channel_price_upd_log">
    <!-- 国际菜单》国际统计>失败统计-弹出层 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>国际失败统计</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                
                <el-table  :data="tableData" :show-header="true" border show-summary style="width: 100%" size="mini" >
                    <el-table-column  prop="state" label="状态" min-width="160" sortable  align="center"> </el-table-column>
                    <el-table-column  prop="total_num" label="计费数" min-width="160" sortable align="center"> </el-table-column>
                </el-table>
                                    
            </el-row>
            
        </el-row>

    </el-dialog>

</div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../../api/api';
// import {dateFtt} from '../../../api/tool';

//import axios from "axios";
export default {
     components: {
    },
    props:{
        prop_change_i:0,
        prop_item:{}
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 

                setTimeout(() => {
                    //获取数据
                    this.getData();
                }, 1);
            }
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            tableData:[],//表格数据
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
        },
         //数据清除
        dataClear(){
            this.tableData = this.$options.data().tableData;//单个恢复初始化
        },

        //查询
        getData(){

            var submit_obj = {};
            submit_obj.param = "fail_state_count";
            submit_obj.cpid = this.prop_item.cpid;//
            submit_obj.yyyyMMdd_start = this.prop_item.yyyyMMdd_start;//
            submit_obj.yyyyMMdd_end = this.prop_item.yyyyMMdd_end;//

            //请求接口
            API.Int_CountServlet(
                submit_obj
            ).then((res) => {
                //console.log(res);
                if (res.recode === 0) {
                    this.tableData = res.list;
                } 
            });
        },

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

</style>