<template>
    <!-- 国际菜单》国际统计 -->
    <div id="app_inter_count_list">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>

            <div class="head_top_title">国际统计</div>

        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

             <!-- 查询条件 -->
            <el-row id="query_condition_id"  class="cursor" style="margin:10px 0px;">
                <el-form  :model="form_data" :inline="true" label-width="80px" style="margin-bottom: 12px;">
                    <el-form-item label="国际客户">
                        <el-select v-model="form_data.cpid" filterable clearable placeholder="默认全部" size="mini" style="width:240px">
                            <el-option
                                v-for="item in userDataList"
                                :key="item.cpid"
                                :label="item.cpid+'('+item.cpname+')'"
                                :value="item.cpid">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="日期" label-width="50px">
                        <el-date-picker
                            v-model="form_data.yyyyMMdds"
                            type="daterange"
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            size="mini" style="width:220px;">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="submit_cha_xun()" size="mini">查询</el-button>
                    </el-form-item>
                </el-form>
            </el-row>
            
            <el-row style="overflow: auto;" :style="{height: ((content_heigth-query_condition_height)+'px')}">
                
                <el-table  :data="tableData" :show-header="true" border show-summary  style="width: 100%" size="mini">
                    <el-table-column  prop="daytime" label="日期" min-width="160" sortable  align="center"> </el-table-column>
                    <el-table-column  prop="success" label="成功计费数" min-width="160" sortable align="center"> </el-table-column>
                    <el-table-column  prop="fail"  label="失败计费数" min-width="160" sortable align="center">
                        <template slot-scope="scope">
                            <template v-if="scope.row.fail == 0">
                                {{scope.row.fail}}
                            </template>
                            <template v-else>
                                <el-button type="text" size="mini" style="color:#f56c6c"  @click="fail_count(scope.row.daytime,scope.row.daytime)">{{scope.row.fail}}</el-button>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column  prop="unknow" label="未知计费数" min-width="160" sortable align="center"> </el-table-column>
                    <el-table-column  prop="total" label="计费总数" min-width="160" sortable align="center"> </el-table-column>
                </el-table>
                <template v-if="tableData.length == 0">
                    <el-row type="flex" justify="center">无数据</el-row>
                </template>
                
            
            </el-row>
        </el-row>
            
         <!-- 国际客户失败统计组件 -->
        <InterCountFailSelect :prop_change_i="prop_count_fail_i"
            :prop_item="prop_count_fail_item"
        ></InterCountFailSelect>
    </div>
</template>
<script>
import API from '../../../api/api';
import {dateFtt} from '../../../api/tool.js';
import InterCountFailSelect from './inter_count_fail_select.vue';//组件  国际客户失败统计组件

//import axios from "axios";
export default {
    components: {
        InterCountFailSelect
    },
    
    data(){
        return{
            content_heigth:500,//内容的高度
            content_width:500,//内容的宽度

            query_condition_height:40,//查询条件的高度

            form_data:{
                cpid:"",//cpid
                yyyyMMdds:[new Date(new Date().getTime()-24*60*60*1000),new Date(new Date().getTime()-24*60*60*1000)],//日期
            },
            
            tableData:[],//表格数据

            userDataList:[],//客户列表数据-请求接口获取


            prop_count_fail_i:0,// 国际客户失败统计组件  弹出框是否可见
            prop_count_fail_item:{
                cpid:"",
                yyyyMMdd_start:"",
                yyyyMMdd_end:"",
                yyyyMMdd_start_log:"",
                yyyyMMdd_end_log:"",
            }

        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        // this.indexPage = this.$options.data().indexPage;//单个恢复初始化
        //重置所有数据
	    Object.assign(this.$data, this.$options.data.call(this));
	    //初始化设置
        this.initSetUp();

        //获取客户列表数据-请求接口获取
        this.getUserDataList();
        
        //查询
        this.submit_cha_xun();


    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 100);

            this.$nextTick(function(){
                //查询条件的高度
                var query_condition = document.getElementById('query_condition_id');
                if(!API.isEmtry(query_condition)){
                    this.query_condition_height = query_condition.clientHeight;
                }
            });

            
        },

        //查询
        submit_cha_xun(){

            var submit_obj = {};
            submit_obj.param = "count";

            submit_obj.cpid = this.form_data.cpid;
            console.log("this.form_data.yyyyMMdds",this.form_data.yyyyMMdds);

            if(API.isEmtry(this.form_data.yyyyMMdds)){
                this.$message.error("请选择日期");
                return;
            }
            var date_start = this.form_data.yyyyMMdds[0];
            var date_end = this.form_data.yyyyMMdds[1];
            if(API.isEmtry(date_start)){
                this.$message.error("请选择开始日期");
                return;
            }
            if(API.isEmtry(date_end)){
                this.$message.error("请选择结束日期");
                return;
            }
            submit_obj.yyyyMMdd_start = dateFtt(date_start,"yyyyMMdd");
            submit_obj.yyyyMMdd_end = dateFtt(date_end,"yyyyMMdd");

            this.prop_count_fail_item.cpid = submit_obj.cpid;
            this.prop_count_fail_item.yyyyMMdd_start_log = submit_obj.yyyyMMdd_start;
            this.prop_count_fail_item.yyyyMMdd_end_log = submit_obj.yyyyMMdd_end;

            //请求接口
            API.Int_CountServlet(
                submit_obj
            ).then((res) => {
                //console.log(res);
                if (res.recode === 0) {
                    this.tableData = res.list;

                    var this_ = this;
                    //给合计
                    let table = document.querySelector('#app_inter_count_list .el-table__footer-wrapper>table');
                    this.$nextTick(()=>{
                        table.rows[0].cells[2].onclick = function(){
                            console.log("-------失败合计总数点击-------");
                            var yyyyMMdd_start = this_.prop_count_fail_item.yyyyMMdd_start_log;
                            var yyyyMMdd_end = this_.prop_count_fail_item.yyyyMMdd_end_log;
                            this_.fail_count(yyyyMMdd_start,yyyyMMdd_end)
                        }
                    })
                } 
            });
        },


        //获取客户列表数据-请求接口获取
        getUserDataList(searchID){
            //请求接口
            API.Int_UserServlet({
                param: "listAISelect",
                searchID:searchID,//输入框填写的查询条件
            }).then((res) => {
                if (res.recode === 0) {
                    this.userDataList = res.list;//客户列表数据--请求接口获取
                }
            });
        },

        //失败统计
        fail_count(yyyyMMdd_start,yyyyMMdd_end){
            this.prop_count_fail_item.cpid = this.form_data.cpid;
            this.prop_count_fail_item.yyyyMMdd_start = yyyyMMdd_start;
            this.prop_count_fail_item.yyyyMMdd_end = yyyyMMdd_end;

            this.prop_count_fail_i ++;// 国际客户失败统计组件  弹出框是否可见
        },

        //返回
        goBack() {
            API.router_to("/inter_index");
        }
    }
};

</script>

<style scoped>

#app_inter_mt_list>>>.el_descriptions_label_class{
    text-align: center;
    width: 80px;
    
}

/*form表单样式调整*/
#app_inter_mt_list>>>.el-divider {
  background-color: #409EFF;
  margin: 5px 0;
} 

</style>